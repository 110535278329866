import React, {
	useEffect,
	useState,
} from 'react';
import {
	Box,
	Button,
	Checkbox,
	CheckboxGroup,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	HStack,
	IconButton,
	Input,
	Radio,
	RadioGroup,
	Select,
	Spinner,
	Stack,
	Text,
	Textarea,
	VStack,
} from '@chakra-ui/react';
import { Form } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon } from '@chakra-ui/icons';

import { useForm } from "react-hook-form";

import Layout from '../Layout/Layout';

// import DescriptionCards from '../Components/DescriptionCards';
import { blue_form_instructionText, welcomeText, } from '../homeDynamicTexts';

import { selectToken, selectDataBlueArchive, selectDataFileArchive } from '../Redux/Reducer';
import { archiveDataBlueSubmit, archiveFileSubmit } from '../Redux/Thunk/ArchiveUpload';
import categoryOfData from '../Components/categoryOfData';


const Incident = () => {
	const dispatch = useDispatch();

	const token = useSelector(state => selectToken(state));
	// const token = "session_6752bf12a29fd875845db47c_1cecfb4d-3379-4722-8285-e362fad5bfad";
	// const profile = useSelector(state => selectProfile(state));
	const dataFile = useSelector(state => selectDataFileArchive(state));
	// console.log(dataFile);
	const data = useSelector(state => selectDataBlueArchive(state));
	// const history = useSelector(state => selectHistoryArchive(state));
	// const userName = useSelector(state => selectUserName(state));


	// tags box
	// const [selectedTag, setSelectedTag] = useState("");
	const [selectedBroadCategory, setSelectedBroadCategory] = useState("");
	const [selectedSpecificCategories, setSelectedSpecificCategories] = useState([]);
	const [otherCategoryValue, setOtherCategoryValue] = useState("");
	const [selectedBroadContexts, setSelectedBroadContexts] = useState([]);
	const [contextSelections, setContextSelections] = useState({});
	const [otherContextValue, setOtherContextValue] = useState({});
	// console.log(contextSelections);

	const broadCategoryOptions = Object.keys(categoryOfData.broad_category_of_offense);
	const specificCategoryOptions =
		selectedBroadCategory &&
		categoryOfData.broad_category_of_offense[selectedBroadCategory]?.specific_category_of_offense;
	const broadContextOptions =
		selectedBroadCategory &&
		categoryOfData.broad_category_of_offense[selectedBroadCategory]?.["broad_context(incident/victim)"];
	// const handleTagChange = (e) => {
	// 	setSelectedTag(e.target.value);
	// 	setSelectedBroadCategory("");
	// 	setSelectedSpecificCategories([]);
	// 	setSelectedBroadContexts([]);
	// 	// setSelectedSpecificContexts([]);
	// 	// setSelectedMinorityType("");
	// };
	const handleBroadCategoryChange = (e) => {
		setSelectedBroadCategory(e.target.value);
		setSelectedSpecificCategories([]);
		setSelectedBroadContexts([]);
		// setSelectedSpecificContexts([]);
		// setSelectedMinorityType("");
	};
	const handleSpecificCategoryChange = (values) => {
		setSelectedSpecificCategories(values);
		setSelectedBroadContexts([]);
		// setSelectedSpecificContexts([]);
		// setSelectedMinorityType("");
	};
	const handleBroadContextChange = (values) => {
		setSelectedBroadContexts(values);
		// setSelectedSpecificContexts([]);
		// setSelectedMinorityType("");
	};
	const handleSpecificContextChange = (contextKey, values = [], subcategory) => {
		setContextSelections((prev) => {
			const isSingleSelectionCategory = contextKey === "সংখ্যালঘু";

			let updatedSelections;

			// Handle categories with subcategories separately
			if (subcategory) {
				updatedSelections = {
					...prev,
					[contextKey]: {
						...prev[contextKey],
						// [subcategory]: Array.isArray(values) ? values : [], // Ensure array for subcategories
						[subcategory]: isSingleSelectionCategory
							? Array.isArray(values) ? values.slice(-1) : [] // Keep only the last selection
							: Array.isArray(values) ? values : [],
					},
				};
			} else {
				updatedSelections = isSingleSelectionCategory
					? {
						...prev,
						[contextKey]: Array.isArray(values) ? values.slice(-1)[0] : values, // Single selection for main category
						// [contextKey]: Array.isArray(values) ? values.slice(-1) : [], // Single selection
					}
					: {
						...prev,
						[contextKey]: Array.isArray(values) ? values : [], // Multiple selection for main category
					};
			}

			// Remove "অন্যান্য" from `otherContextValue` if unchecked
			const keyForOtherContext = subcategory ? `${contextKey}-${subcategory}` : contextKey;
			if (!values.includes("অন্যান্য") && otherContextValue[keyForOtherContext]) {
				setOtherContextValue((prevOtherContextValue) => {
					const { [keyForOtherContext]: _, ...rest } = prevOtherContextValue;
					return rest;
				});
			}

			return updatedSelections;
		});
	};
	const handleOtherContextChange = (key, value) => {
		setOtherContextValue((prev) => ({
			...prev,
			[key]: value,
		}));
	};


	// form data
	const [date, setDate] = useState('');
	const [locationDistrict, setLocationDistrict] = useState('');
	const [locationUpazila, setLocationUpazila] = useState('');
	const [locationVillage, setLocationVillage] = useState('');
	const [victims, setVictims] = useState([
		{ name: '', age: '', gender: '', district: '', upazila: '', village: '', phone: '', profession: '', relationship: '', relationshipDetail: '' },]);
	// console.log(victims);
	const [accusedList, setAccusedList] = useState([
		{ type: '', details: '', name: '', ageGroup: '', gender: '', district: '', upazila: '', village: '', phone: '', profession: '', organization: '', },
	]);
	// console.log(accusedList);
	const [selectedMediaOption, setSelectedMediaOption] = useState('');
	const [mediaWhere, setMediaWhere] = useState('');
	const [mediaWhen, setMediaWhen] = useState('');
	const [selectedLawOption, setSelectedLawOption] = useState('');
	const [lawDetails, setLawDetails] = useState('');
	const [lawWhy, setLawWhy] = useState('');
	const [selectedActionsOption, setSelectedActionsOption] = useState('')
	const [actionsDetails, setActionsDetails] = useState('');
	const [incidentDetails, setIncidentDetails] = useState('');
	const [link, setLink] = useState("");
	const [links, setLinks] = useState([]);
	const [incidentInfoGiver, setIncidentInfoGiver] = useState(
		{ name: '', age: '', gender: '', district: '', upazila: '', village: '', phone: '', profession: '', }
	);
	// console.log(incidentInfoGiver);

	// Add a new victim
	const addVictim = () => {
		setVictims([
			...victims,
			{ name: '', age: '', gender: '', district: '', upazila: '', village: '', phone: '', profession: '', relationship: '', relationshipDetail: '' },
		]);
	};
	// Remove a victim
	const removeVictim = (index) => {
		setVictims(victims.filter((_, i) => i !== index));
	};
	// Handle changes in a victim's details
	const handleChange = (index, field, value) => {
		const updatedVictims = [...victims];
		updatedVictims[index][field] = value;
		setVictims(updatedVictims);
	};
	// Add a new accused entry
	const addAccused = () => {
		setAccusedList([
			...accusedList,
			{ type: '', details: '', name: '', ageGroup: '', gender: '', district: '', upazila: '', village: '', phone: '', profession: '', organization: '', },
			// { type: 'সম্ভাব্য', details: '', name: '', ageGroup: '', gender: '', district: '', upazila: '', village: '', phone: '', profession: '', organization: '', },
		]);
	};
	// Remove an accused entry
	const removeAccused = (index) => {
		setAccusedList(accusedList.filter((_, i) => i !== index));
	};
	// Handle field changes for each accused entry
	const handleChange2 = (index, field, value) => {
		const updatedAccusedList = [...accusedList];
		updatedAccusedList[index][field] = value;
		setAccusedList(updatedAccusedList);
	};

	// file upload 
	// const [uploadFlag, setUploadFlag] = useState(false);
	// for multiple file system
	const [selectedFiles, setSelectedFiles] = useState(null);
	const [previews, setPreviews] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	// console.log(isLoading);
	const [error, setError] = useState('');
	useEffect(() => {
		// Cleanup the object URLs when component unmounts or previews change
		return () => {
			previews.forEach(file => URL.revokeObjectURL(file.url));
		};
	}, [previews]);
	const handleFileChange = (event) => {
		const files = Array.from(event.target.files);

		const maxSize = 2 * 1024 * 1024 * 1024; // 2GB in bytes
		// const maxSize = 1 * 1024 * 1024 * 1024; // 1GB in bytes

		const validFiles = [];
		const filePreviews = [];
		files.forEach(file => {
			if (file.size > maxSize) {
				setError(`File "${file.name}" exceeds the 2GB size limit.`);
			} else {
				validFiles.push(file);
				const fileURL = URL.createObjectURL(file);
				filePreviews.push({ name: file.name, type: file.type, url: fileURL });
			}
		});
		if (validFiles.length > 0) {
			setSelectedFiles(validFiles);
			setPreviews(filePreviews);
			setError(''); // Clear any previous errors
		}
	};
	const [uploadedFileIds, setUploadedFileIds] = useState([]);  // To store response IDs
	// console.log(uploadedFileIds);
	// Monitor changes in dataFile to capture new file IDs
	useEffect(() => {
		if (dataFile && dataFile.ivpfile_id) {
			// Update state with the new file ID
			/*
			setUploadedFileIds((prevIds) => {
				if (!prevIds.includes(dataFile.ivpfile_id)) {
					return [...prevIds, dataFile.ivpfile_id];
				}
				return prevIds;
			});
			*/
			setUploadedFileIds((prevFiles) => {
				// Check if the file ID already exists in the previous state
				if (!prevFiles.some(file => file.id === dataFile.ivpfile_id)) {
					// If not, add the new file object to the state
					return [...prevFiles, { id: dataFile.ivpfile_id, mime_type: dataFile.mime_type }];
				}
				// If it exists, return the previous state unchanged
				return prevFiles;
			});
			// setSelectedFiles(null);
			// setPreviews([]);
		}
	}, [dataFile]); // Trigger effect whenever dataFile changes	
	const handleFileUpload = async (event) => {
		event.preventDefault();

		if (!selectedFiles || selectedFiles.length === 0) {
			toast.error("Please select at least one file to upload.");
			return;
		}

		setIsLoading(true); // Start loading before the upload process begins
		setError(''); // Clear any existing errors

		try {
			const uploadResults = await Promise.all(
				selectedFiles.map(async (file) => {
					const formData = new FormData();
					formData.append('ivpfile', file);

					try {
						await dispatch(archiveFileSubmit(formData, token));
						// toast.success(`File ${file.name} uploaded successfully.`);
					} catch (error) {
						// console.error(`Error uploading file ${file.name}:`, error);
						throw new Error(`Failed to upload file ${file.name}.`);
					}
				})
			);

			console.log("All files uploaded:", uploadResults); // Optional: Debug upload results
		} catch (error) {
			setError(error.message || "An error occurred during file upload.");
			toast.error(error.message);
		} finally {
			// setSelectedFiles(null);
			// setPreviews([]);
			// await new Promise((resolve) => setTimeout(resolve, 10000));
			setIsLoading(false); // Stop loading after all uploads complete or an error occurs
		}
	};

	const handleAddLink = () => {
		if (link.trim() !== "") {
			setLinks([...links, link.trim()]);
			setLink("");
		}
	};
	const handleDeleteLink = (index) => {
		setLinks(links.filter((_, i) => i !== index));
	};

	// create as like json file to send data submit
	const createJsonData = () => {
		// for specific broad category
		let specificCategory = [...selectedSpecificCategories];
		// Check if "অন্যান্য" exists and replace it with the formatted value
		const othersIndex = specificCategory.findIndex((item) => item === "অন্যান্য");
		if (othersIndex !== -1) {
			// Replace "অন্যান্য" with "অন্যান্য-(the value of otherCategoryValue)"
			specificCategory[othersIndex] = `অন্যান্য-${otherCategoryValue}`;
		}

		// for context
		const contextSet = Object.keys(contextSelections).reduce((acc, key) => {
			const selection = contextSelections[key];

			if (typeof selection === 'object' && !Array.isArray(selection)) {
				// Handle nested objects like "সংখ্যালঘু"
				acc[key] = Object.keys(selection).reduce((subAcc, subKey) => {
					if (subKey === "অন্যান্য" && otherContextValue[`${key}-অন্যান্য`]) {
						subAcc[subKey] = `অন্যান্য-${otherContextValue[`${key}-অন্যান্য`]}`; // Store as a string
					} else {
						subAcc[subKey] = Array.isArray(selection[subKey]) ? selection[subKey][0] : selection[subKey]; // Ensure values are strings
					}
					return subAcc;
				}, {});
			} else if (Array.isArray(selection)) {
				// Handle arrays like "১৯৭১/মুক্তিযুদ্ধ"
				acc[key] = selection.map(item =>
					item === "অন্যান্য" && otherContextValue[key]
						? `অন্যান্য-${otherContextValue[key]}`
						: item
				);
			} else {
				acc[key] = selection; // Default fallback
			}

			return acc;
		}, {});


		return {
			data_type: 'blue',
			complex_category: {
				// tag: selectedTag,
				broad_category: selectedBroadCategory,
				// specific_category: selectedSpecificCategories,
				specific_category: specificCategory,
				// context: contextSelections,
				context: contextSet,
			},
			date_of_incident: date,
			place_of_occurrence: {
				district: locationDistrict,
				upazila: locationUpazila,
				village: locationVillage,
				phone: "",
			},
			victim_information: victims.map(victim => ({
				name: victim.name,
				age: victim.age,
				gender: victim.gender,
				address: {
					district: victim.district,
					upazila: victim.upazila,
					village: victim.village,
					phone: victim.phone,
				},
				occupation: victim.profession,
				relation_with_victim: {
					relation: victim.relationship,
					extra: victim.relationshipDetail,
				},
			})),
			those_accused: accusedList.map(accused => ({
				unknown: accused.type,
				details: accused.details,
				name: accused.name,
				age: accused.ageGroup,
				gender: accused.gender,
				address: {
					district: accused.district,
					upazila: accused.upazila,
					village: accused.village,
					phone: accused.phone,
				},
				occupation: accused.profession,
				organization: accused.organization,
			})),
			in_media: {
				choice: selectedMediaOption,
				extra: `when:${mediaWhen}, where:${mediaWhere}`,
			},
			authorities_informed: {
				choice: selectedLawOption,
				extra: `YesDetails:${lawDetails}, NoWhy:${lawWhy}`,
			},
			step_by_government: {
				choice: selectedActionsOption,
				extra: actionsDetails,
			},
			incident_description: incidentDetails,
			// files: [{ "id": "file12345", "mime_type": "application/pdf" }],
			files: uploadedFileIds,
			links: links,
			provider_info: incidentInfoGiver,
		};
	};
	// const jsonData = createJsonData();
	// console.log(jsonData);

	const handleSubmit = event => {
		// event.preventDefault();

		// if (contextSelections.length > 0) {
		// if (contextSelections !== undefined) {
		if (Object.keys(contextSelections).length > 0) {

			const jsonData = createJsonData();
			// console.log(jsonData);
			dispatch(archiveDataBlueSubmit(jsonData, token));

			// setUploadFlag(true);
		}
		else {
			toast.error('Please select at least one context.');
		}
	};

	useEffect(() => {
		if (dataFile.success) {
			toast.success(dataFile.success);
			// setUploadFlag(false);
		}
		if (dataFile.error.message) {
			toast.error(dataFile.error.message);
			// setUploadFlag(false);
		}
	}, [dataFile.error.message, dataFile.success, dispatch]);

	useEffect(() => {
		if (data.success) {
			toast.success(data.success);
			// setUploadFlag(false);

			setSelectedBroadCategory("");
			setSelectedSpecificCategories([]);
			setOtherCategoryValue("");
			setSelectedBroadContexts([]);
			setContextSelections({});
			setOtherContextValue({});
			setDate('');
			setLocationDistrict('');
			setLocationUpazila('');
			setLocationVillage('');
			setVictims([
				{ name: '', age: '', gender: '', district: '', upazila: '', village: '', phone: '', profession: '', relationship: '', relationshipDetail: '' },]);
			setAccusedList([
				{ type: '', details: '', name: '', ageGroup: '', gender: '', district: '', upazila: '', village: '', phone: '', profession: '', organization: '', },
			]);
			setSelectedMediaOption('');
			setMediaWhere('');
			setMediaWhen('');
			setSelectedLawOption('');
			setLawDetails('');
			setLawWhy('');
			setSelectedActionsOption('')
			setActionsDetails('');
			setIncidentDetails('');
			setIncidentInfoGiver('');
			setSelectedFiles(null);
			setPreviews([]);
			setError('');
			setUploadedFileIds([]);
		}
		if (data.error.message) {
			toast.error(data.error.message);
			// setUploadFlag(false);
		}
	}, [data.error.message, data.success, dispatch]);


	function validateNestedData(data) {
		if (data === undefined || data === null) {
			return false; // Invalid if data is null or undefined
		}

		if (typeof data === 'object') {
			if (Array.isArray(data)) {
				// Check if at least one valid value exists in the array
				return data.some(item => validateNestedData(item));
			} else {
				// Check if at least one valid value exists in the object
				return Object.values(data).some(value => validateNestedData(value));
			}
		}

		// For primitive values, check if they are valid (not empty or whitespace)
		return data.toString().trim() !== '';
	};

	const {
		register,
	//	handleSubmit,
		formState: { errors },
		setValue,	
	} = useForm();
	//<Box maxW="1200px" mx="auto" px={4} py={8}></Box>
//	const extraField2Value = watch("extraField2", locationUpazila);

	return (
		<Layout>
			<Text
				fontSize={["lg", "lg", "2xl"]}
				fontWeight="bold"
				color='black'
				textAlign={'center'}
				mb={2}
				pt={4}
			>
				{welcomeText}
			</Text>

			<Text
				// fontSize={["sm", "md", "lg"]}
				fontSize={["xs", "xs", "sm"]}
				fontWeight="bold"
				color='black'
				// textAlign={'center'}
				// mb={2}
				px={4}
			>
				{blue_form_instructionText}
			</Text>

			<Box maxW="container.lg" mx="auto" px={4} py={8}>
				<Box
					// rowSpan={3}
					// colSpan={[5, 5, 3, 3, 3]}
					boxShadow='dark-lg'
					p={[3, 6]}
					rounded='md'
					w={'100%'}
					mb={5}
				>
					<Box maxW={"100%"} mx="auto" fontSize={["xs", "sm", "md"]}>
						{/* Select Broad Category */}
						{/* {selectedTag && ( */}
						<Stack gap={[2, 5]} marginYZ={3} direction={['column', 'column', 'row',]} fontSize={["xs", "sm", "md"]}>
							<Text
								// fontSize={["md", "md", "lg"]}
								fontWeight="semibold"
								color='black'
								textAlign={'left'}
								w={["100%", "100%", "35%"]}
							>
								অপরাধের ধরন ?
							</Text>

							<Select
								placeholder="অপরাধের ধরন নির্বাচন করুন"
								value={selectedBroadCategory}
								onChange={handleBroadCategoryChange}
								mb={4}
								w={["100%", "100%", "65%"]}
							>
								{broadCategoryOptions.map((category, index) => (
									<option key={index} value={category}>
										{category}
									</option>
								))}
							</Select>
						</Stack>
						{/* )} */}

						{/* Select Specific Category */}
						{selectedBroadCategory && specificCategoryOptions && (
							<Stack gap={[2, 5]} marginYZ={3} direction={['column', 'column', 'row',]}>
								<Text
									// fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
									w={["100%", "100%", "35%"]}
								>
									অপরাধের ধরন আরেকটু নির্দিষ্ট করুন
								</Text>

								<CheckboxGroup value={selectedSpecificCategories} onChange={handleSpecificCategoryChange} w={["100%", "100%", "65%"]}>
									<Stack spacing={2} mb={4}>
										{specificCategoryOptions.map((option, index) => (
											<Checkbox key={index} value={option}>
												{option}
											</Checkbox>
										))}
										{/* Input field for "অন্যান্য" */}
										{selectedSpecificCategories.some((item) => item.startsWith("অন্যান্য")) && (
											<Input
												placeholder="অপরাধের ধরন লিখুন"
												value={otherCategoryValue}
												// onChange={handleOtherCategoryChange}
												onChange={(e) => setOtherCategoryValue(e.currentTarget.value)}
												mt={2}
											/>
										)}
									</Stack>
								</CheckboxGroup>
							</Stack>
						)}

						{/* Select Broad Context */}
						{selectedSpecificCategories.length > 0 && broadContextOptions && (
							<Stack gap={[2, 5]} marginYZ={3} direction={['column', 'column', 'row',]}>
								<Text
									// fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
									w={["100%", "100%", "35%"]}
								>
									অপরাধের প্রেক্ষাপট
								</Text>

								<CheckboxGroup value={selectedBroadContexts} onChange={handleBroadContextChange} w={["100%", "100%", "65%"]}>
									<Stack spacing={2} mb={4}>
										{broadContextOptions.map((option, index) => (
											<Checkbox key={index} value={option}>
												{option}
											</Checkbox>
										))}
									</Stack>
								</CheckboxGroup>
							</Stack>
						)}

						{/* Select Specific Context */}
						{
							selectedBroadContexts?.length > 0 &&
							<Stack>
								<Text
									// fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
									mb={2}
								>
									অপরাধের প্রেক্ষাপট আরেকটু নির্দিষ্ট করুন
								</Text>

								<Stack gap={[2, 5]} marginYZ={3} direction={['column', 'column', 'row',]}>

									{selectedBroadContexts.map((contextKey) => {
										const specificContextOptions =
											categoryOfData.broad_category_of_offense[selectedBroadCategory]?.[
											"specific_context(incident/victim)"
											][contextKey] || {};

										return (
											<Box key={contextKey}>
												<Text fontWeight="semibold">
													{/* নির্দিষ্ট প্রেক্ষাপট ({contextKey}) */}
													{contextKey}
												</Text>

												{/* Main Category */}
												{Array.isArray(specificContextOptions) && (
													<CheckboxGroup
														value={contextSelections[contextKey] || []}
														onChange={(values) => handleSpecificContextChange(contextKey, values, '')}
													>
														<Stack>
															{specificContextOptions.map((option) => (
																<Checkbox key={option} value={option}>
																	{option}
																</Checkbox>
															))}
															{/* Input for "অন্যান্য" */}
															{contextSelections[contextKey]?.includes("অন্যান্য") && (
																<Input
																	placeholder="প্রেক্ষাপটের ধরন লিখুন"
																	value={otherContextValue[contextKey] || ""}
																	onChange={(e) =>
																		handleOtherContextChange(contextKey, e.target.value)
																	}
																	mt={2}
																/>
															)}
														</Stack>
													</CheckboxGroup>
												)}

												{/* Subcategories */}
												{!Array.isArray(specificContextOptions) &&
													Object.entries(specificContextOptions).map(
														([subcategory, options], index) => (
															<Box key={index}>
																<Text fontWeight="bold">{subcategory}</Text>
																<CheckboxGroup
																	value={
																		Array.isArray(contextSelections[contextKey]?.[subcategory])
																			? contextSelections[contextKey][subcategory]
																			: []
																	}
																	onChange={(values) =>
																		handleSpecificContextChange(contextKey, values, subcategory)
																	}
																>
																	<Stack>
																		{options.map((option) => (
																			<Checkbox key={option} value={option}>
																				{option}
																			</Checkbox>
																		))}
																		{/* Input for "অন্যান্য" in subcategories */}
																		{contextSelections[contextKey]?.[subcategory]?.includes(
																			"অন্যান্য"
																		) && (
																				<Input
																					placeholder="প্রেক্ষাপটের ধরন লিখুন"
																					value={
																						otherContextValue[`${contextKey}-${subcategory}`] ||
																						""
																					}
																					onChange={(e) =>
																						handleOtherContextChange(
																							`${contextKey}-${subcategory}`,
																							e.target.value
																						)
																					}
																					mt={2}
																				/>
																			)}
																	</Stack>
																</CheckboxGroup>
															</Box>
														)
													)}
											</Box>
										);
									})}
								</Stack>
							</Stack>
						}
					</Box>
				</Box>

				{
					// (Object.keys(contextSelections).length > 0) &&
					validateNestedData(contextSelections) &&
					<Box
						boxShadow='dark-lg'
						p={[3, 6]}
						rounded='md'
						w={'100%'}
					>
						<Text
							fontSize={["md", "lg", "xl"]}
							fontWeight="bold"
							color='black'
							textAlign={'center'}
							mb={2}
						>
							তথ্য প্রমাণ এবং ব্যাক্তিগত তথ্য
						</Text>

						<Box fontSize={["xs", "sm", "md"]}>
							{/* <Form onSubmit={handleSubmit}> */}
							<Form>
								{/* <Box maxW="800px" mx="auto" p={5} boxShadow="md" rounded="lg" bg="white"> */}
								<VStack spacing={5} align="stretch">
									{/* 1. Incident Date */}
									<FormControl >
										<FormLabel fontWeight="bold">কবে ঘটনা ঘটেছে?</FormLabel>
										<Input type="date" value={date} required onChange={(e) => setDate(e.target.value)} />
									</FormControl>

									{/* 2. Incident Location */}
									<FormControl isRequired>
										<FormLabel fontWeight="bold">কোথায় ঘটনা ঘটেছে?</FormLabel>
										<Input
											marginY={2}
											placeholder="জেলার নাম লিখুন"
											//value={locationDistrict}
											{...register("extraField1", { required: "This field is required" })}
											onChange={(e) => {
												setLocationDistrict(e.target.value); // Update local state
												setValue("extraField1", e.target.value, { shouldValidate: true });
											  }}
											  value={locationDistrict}
										/>
										{errors.extraField1 && <p>{errors.extraField1.message}</p>}
										<Input
											marginY={2}
											placeholder="উপজেলার নাম লিখুন"
											{...register("extraField2", { required: "This field is required" })}
											onChange={(e) => {
												setLocationUpazila(e.target.value); // Update local state
												setValue("extraField2", e.target.value, { shouldValidate: true });
											  }}
											  value={locationUpazila}

											//											required
										/>
										  {errors.extraField2 && <p>{errors.extraField2.message}</p>}
										<Input
											marginY={2}
											placeholder="গ্রামের নাম লিখুন"
//											placeholder="উপজেলার নাম লিখুন"
											{...register("extraField3", { required: "This field is required" })}
											onChange={(e) => {
												setLocationVillage(e.target.value);
												setValue("extraField3", e.target.value, { shouldValidate: true });
											  }}

											value={locationVillage}
//											{...register("extraFiel3", { required: "This field is required" })}
										//	required
										/>		
										  {errors.extraField3 && <p>{errors.extraField3.message}</p>}


									</FormControl>

									{/* 3. Victim Information */}
									<Box>
										<FormLabel fontWeight="bold">ভুক্তভোগীর তথ্য প্রদান করুন</FormLabel>
										{victims.map((victim, index) => (
											<Stack key={index} spacing={3} mb={3} p={3} borderWidth="1px" borderRadius="md">
												<IconButton
													aria-label="Remove victim"
													icon={<CloseIcon />}
													colorScheme="red"
													size="sm"
													alignSelf="flex-end"
													onClick={() => removeVictim(index)}
												/>
												<FormControl>
													<FormLabel>ভুক্তভোগীর নাম</FormLabel>
													<Input
														placeholder="নাম লিখুন"
														value={victim.name}
														onChange={(e) => handleChange(index, 'name', e.target.value)}
														required
													/>
												</FormControl>

												<FormControl>
													<FormLabel>ভুক্তভোগীর বয়স</FormLabel>
													<Input
														placeholder="বয়স লিখুন"
														onChange={(e) => handleChange(index, 'age', e.target.value)}
														required
													/>
												</FormControl>

												<FormControl>
													<FormLabel>ভুক্তভোগীর লিঙ্গ</FormLabel>
													<Select
														placeholder="লিঙ্গ নির্বাচন করুন"
														value={victim.gender}
														onChange={(e) => handleChange(index, 'gender', e.target.value)}
													>
														<option value="পুরুষ">পুরুষ</option>
														<option value="নারী">নারী</option>
														<option value="অন্যান্য">অন্যান্য</option>
													</Select>
												</FormControl>

												<FormControl>
													<FormLabel>ভুক্তভোগীর ঠিকানা (জেলা,উপজেলা,গ্রাম)</FormLabel>
													<Input
														placeholder="জেলা নাম লিখুন"
														value={victim.district}
														onChange={(e) => handleChange(index, 'district', e.target.value)}
													/>
													<Input
														marginY={2}
														placeholder="উপজেলা নাম লিখুন"
														value={victim.upazila}
														onChange={(e) => handleChange(index, 'upazila', e.target.value)}
													/>
													<Input
														placeholder="গ্রাম নাম লিখুন"
														value={victim.village}
														onChange={(e) => handleChange(index, 'village', e.target.value)}
													/>
												</FormControl>

												<FormControl>
													<FormLabel>ফোন নাম্বার বা যোগাযোগকারীর নাম্বার</FormLabel>
													<Input
														placeholder="ফোন নাম্বার লিখুন"
														value={victim.phone}
														onChange={(e) => handleChange(index, 'phone', e.target.value)}
														required
													/>
												</FormControl>

												<FormControl>
													<FormLabel>ভুক্তভোগীর পেশা</FormLabel>
													<Input
														placeholder="পেশা লিখুন"
														value={victim.profession}
														onChange={(e) => handleChange(index, 'profession', e.target.value)}
													/>
												</FormControl>

												<FormControl>
													<FormLabel>ভুক্তভোগীর সাথে তথ্য-প্রদানকারীর সম্পর্ক</FormLabel>
													<Select
														placeholder="সম্পর্ক নির্বাচন করুন"
														value={victim.relationship}
														onChange={(e) => handleChange(index, 'relationship', e.target.value)}
													>
														<option value="নিজে">নিজে</option>
														<option value="আত্মীয়">আত্মীয় (লিখুন কি ধরনের  আত্মীয়)</option>
														<option value="প্রতিবেশী">প্রতিবেশী</option>
														<option value="বন্ধু">বন্ধু</option>
														<option value="অন্যান্য">অন্যান্য</option>
													</Select>
													{(victim.relationship === 'আত্মীয়' || victim.relationship === 'অন্যান্য') && (
														<Input
															mt={2}
															placeholder="বিস্তারিত লিখুন"
															value={victim.relationshipDetail}
															onChange={(e) => handleChange(index, 'relationshipDetail', e.target.value)}
														/>
													)}
												</FormControl>
											</Stack>
										))}
										<Button bgColor={'red.600'} color={'white'} onClick={addVictim}>
											নতুন যোগ করুন
										</Button>
									</Box>

									{/* 4. Allegations */}
									<Box>
										<FormLabel fontWeight="bold">যাদের বিরুদ্ধে অভিযোগ, সেইসব ব্যাক্তির তথ্য প্রদান করুন</FormLabel>
										{/* <Stack spacing={3} mb={3}>
										
										<Stack spacing={3} p={3} borderWidth="1px" borderRadius="md">
											<FormControl>
												<FormLabel>অজ্ঞাত</FormLabel>
												<Input
													mt={2}
													placeholder="বিস্তারিত লিখুন"
													value={accusedList.find((accused) => accused.type === 'অজ্ঞাত')?.details || ''}
													onChange={(e) => handleUnknownChange(e.target.value)}
												/>
											</FormControl>
										</Stack>

										
										<FormLabel>সম্ভাব্য</FormLabel>
										{accusedList
											.map((accused, originalIndex) => ({ ...accused, originalIndex }))
											.filter((accused) => accused.type === 'সম্ভাব্য')
											.map((accusedWithIndex, filteredIndex) => {
												const { originalIndex } = accusedWithIndex; // Preserve the original index

												return (
													<Stack key={originalIndex} spacing={3} p={3} borderWidth="1px" borderRadius="md"> */}
										{accusedList.map((accused, index) => (
											<Stack key={index} spacing={3} mb={3} p={3} borderWidth="1px" borderRadius="md">
												<IconButton
													aria-label="Remove accused"
													icon={<CloseIcon />}
													colorScheme="red"
													size="sm"
													alignSelf="flex-end"
													// onClick={() => removeAccused(originalIndex)}
													onClick={() => removeAccused(index)}
												/>

												<Box>
													<Text fontWeight="bold" mb={2}>
														অভিযুক্ত ব্যাক্তির পরিচয় জানা আছে?
													</Text>
													<RadioGroup
														// onChange={(e) => {
														// 	setSelectedMediaOption(e); 
														// }}
														value={accused.type}
														onChange={(e) => handleChange2(index, 'type', e)}
													>
														<Stack spacing={4} flexDirection={'row'}>
															<Radio value="true">হ্যাঁ</Radio>
															<Radio value="false">না</Radio>
															{/* <Radio value="unknown">অজানা</Radio> */}
														</Stack>
													</RadioGroup>
													{accusedList[index]['type'] === true && (
														<Box mt={4}>
															<Input
																mt={2}
																placeholder="বিস্তারিত লিখুন"
																value={accused.details}
																onChange={(e) => handleChange2(index, 'details', e.target.value)}
															/>
														</Box>
													)}
												</Box>

												<Text fontWeight="bold" mt={2}>
													সম্ভাব্য অভিযুক্তের তথ্য প্রদান করুন
												</Text>

												<FormControl>
													<FormLabel>অভিযুক্তের নাম</FormLabel>
													<Input
														placeholder="নাম লিখুন"
														// value={accusedList[originalIndex]?.name || ''}
														// onChange={(e) => handleChange2(originalIndex, 'name', e.target.value)}
														value={accused.name}
														onChange={(e) => handleChange2(index, 'name', e.target.value)}
													/>
												</FormControl>

												<FormControl>
													<FormLabel>অভিযুক্তের বয়স</FormLabel>
													<Select
														placeholder="বয়সের গ্রুপ নির্বাচন করুন"
														// value={accusedList[originalIndex]?.ageGroup || ''}
														// onChange={(e) => handleChange2(originalIndex, 'ageGroup', e.target.value)}
														value={accused.ageGroup}
														onChange={(e) => handleChange2(index, 'ageGroup', e.target.value)}
													>
														<option value="১৮ বছরের কম">১৮ বছরের কম</option>
														<option value="১৮ - ৩০ বছর">১৮ - ৩০ বছর</option>
														<option value="৩১ - ৪৫ বছর">৩১ - ৪৫ বছর</option>
														<option value="৪৬ - ৬০ বছর">৪৬ - ৬০ বছর</option>
														<option value="৬০+ বছর">৬০+ বছর</option>
													</Select>
												</FormControl>

												<FormControl>
													<FormLabel>অভিযুক্তের লিঙ্গ</FormLabel>
													<Select
														placeholder="লিঙ্গ নির্বাচন করুন"
														// value={accusedList[originalIndex]?.gender || ''}
														// onChange={(e) => handleChange2(originalIndex, 'gender', e.target.value)}
														value={accused.gender}
														onChange={(e) => handleChange2(index, 'gender', e.target.value)}
													>
														<option value="পুরুষ">পুরুষ</option>
														<option value="নারী">নারী</option>
														<option value="অন্যান্য">অন্যান্য</option>
													</Select>
												</FormControl>

												<FormControl>
													<FormLabel>অভিযুক্তের ঠিকানা (জেলা,উপজেলা,গ্রাম)</FormLabel>
													<Input
														placeholder="জেলা নাম লিখুন"
														// value={accusedList[originalIndex]?.district || ''}
														// onChange={(e) => handleChange2(originalIndex, 'district', e.target.value)}
														value={accused.district}
														onChange={(e) => handleChange2(index, 'district', e.target.value)}
													/>
													<Input
														marginY={2}
														placeholder="উপজেলা নাম লিখুন"
														// value={accusedList[originalIndex]?.upazila || ''}
														// onChange={(e) => handleChange2(originalIndex, 'upazila', e.target.value)}
														value={accused.upazila}
														onChange={(e) => handleChange2(index, 'upazila', e.target.value)}
													/>
													<Input
														placeholder="গ্রাম নাম লিখুন"
														// value={accusedList[originalIndex]?.village || ''}
														// onChange={(e) => handleChange2(originalIndex, 'village', e.target.value)}
														value={accused.village}
														onChange={(e) => handleChange2(index, 'village', e.target.value)}
													/>
												</FormControl>

												<FormControl>
													<FormLabel>অভিযুক্তের ফোন নাম্বার</FormLabel>
													<Input
														placeholder="ফোন নাম্বার লিখুন"
														// value={accusedList[originalIndex]?.phone || ''}
														// onChange={(e) => handleChange2(originalIndex, 'phone', e.target.value)}
														value={accused.phone}
														onChange={(e) => handleChange2(index, 'phone', e.target.value)}
													/>
												</FormControl>

												<FormControl>
													<FormLabel>অভিযুক্তের পেশা</FormLabel>
													<Input
														placeholder="পেশা লিখুন"
														// value={accusedList[originalIndex]?.profession || ''}
														// onChange={(e) => handleChange2(originalIndex, 'profession', e.target.value)}
														value={accused.profession}
														onChange={(e) => handleChange2(index, 'profession', e.target.value)}
													/>
												</FormControl>

												<FormControl>
													<FormLabel>অভিযুক্ত কোন সংস্থা/সংগঠন-এর সাথে যুক্ত?</FormLabel>
													<Select
														placeholder="নির্বাচন করুন"
														// value={accusedList[originalIndex]?.organization || ''}
														// onChange={(e) => handleChange2(originalIndex, 'organization', e.target.value)}
														value={accused.organization}
														onChange={(e) => handleChange2(index, 'organization', e.target.value)}
													>
														<option value="পুলিশ">পুলিশ</option>
														<option value="আর্মি">আর্মি</option>
														<option value="আনসার">আনসার</option>
														<option value="BGB">BGB</option>
														<option value="RAB">RAB</option>
														<option value="DGFI">DGFI</option>
														<option value="১৯৭১-এর স্বাধীনতা বিরোধী শক্তি">১৯৭১-এর স্বাধীনতা বিরোধী শক্তি</option>
														<option value="SAD/সমন্বয়ক">SAD/সমন্বয়ক</option>
														<option value="ছাত্রলীগ/আওয়ামীলীগ">ছাত্রলীগ/আওয়ামীলীগ</option>
														<option value="যুবদল/বিএনপি">যুবদল/বিএনপি</option>
														<option value="জামাত-ই-ইসলাম/ইসলামী ছাত্র শিবির">জামাত-ই-ইসলাম/ইসলামী ছাত্র শিবির</option>
														<option value="অন্যান্য ইসলামিক মৌলবাদী দল">অন্যান্য ইসলামিক মৌলবাদী দল</option>
														<option value="মব">মব</option>
														<option value="সেটেলার">সেটেলার</option>
														<option value="কিশোর গ্যাং">কিশোর গ্যাং</option>
														<option value="স্থানীয় সন্ত্রাসী">স্থানীয় সন্ত্রাসী</option>
														<option value="সামাজিক যোগাযোগ মাধ্যমের ইনফ্লুয়েন্সর">সামাজিক যোগাযোগ মাধ্যমের ইনফ্লুয়েন্সর</option>
														<option value="অন্যান্য">অন্যান্য</option>
													</Select>
												</FormControl>
												{/* </Stack>
												);
											})} */}
											</Stack>
										))}
										<Button bgColor={'red.600'} color={'white'} onClick={addAccused} justifyContent={'left'} w={'fit-content'}>
											নতুন যোগ করুন
										</Button>
										{/* </Stack> */}
									</Box>

									{/* 5. Media Presence */}
									<Box>
										<Text fontWeight="bold" mb={2}>
											ঘটনাটি কী কোনো গণমাধ্যমে প্রকাশিত হয়েছে?
										</Text>
										<RadioGroup
											onChange={(e) => {
												setSelectedMediaOption(e); setMediaWhere(''); setMediaWhen('');
											}}
											value={selectedMediaOption}
										>
											<Stack spacing={4} flexDirection={'row'}>
												<Radio value="yes">হ্যাঁ</Radio>
												<Radio value="no">না</Radio>
												<Radio value="unknown">অজানা</Radio>
											</Stack>
										</RadioGroup>
										{selectedMediaOption === 'yes' && (
											<Box mt={4}>
												<FormControl>
													<FormLabel>ঘটনাটি কোন গণমাধ্যমে প্রকাশিত হয়েছে ?</FormLabel>
													<Input placeholder="গণমাধ্যমের নাম লিখুন" value={mediaWhere} onChange={(e) => setMediaWhere(e.target.value)} />
												</FormControl>
												<FormControl mt={4}>
													<FormLabel>ঘটনাটি কবে গণমাধ্যমে প্রকাশিত হয়েছে ?</FormLabel>
													<Input type="date" value={mediaWhen} onChange={(e) => setMediaWhen(e.target.value)} />
												</FormControl>
											</Box>
										)}
									</Box>

									{/* 6. Notified Authorities */}
									<Box>
										<Text fontWeight="bold" mb={2}>
											এই ঘটনার জন্য কোনো কর্তৃপক্ষকে জানানো হয়েছে কিনা?
										</Text>
										<RadioGroup
											onChange={(e) => {
												setSelectedLawOption(e); setLawDetails(''); setLawWhy('');
											}}
											value={selectedLawOption}
										>
											<Stack spacing={4} flexDirection={'row'}>
												<Radio value="yes">হ্যাঁ</Radio>
												<Radio value="no">না</Radio>
												<Radio value="unknown">অজানা</Radio>
											</Stack>
										</RadioGroup>
										{selectedLawOption === 'yes' && (
											<Box mt={4}>
												<FormControl>
													<FormLabel>বিস্তারিত লিখুন - মামলা বা অভিযোগের বৃত্তান্ত</FormLabel>
													<Input placeholder="বিস্তারিত লিখুন" value={lawDetails} onChange={(e) => setLawDetails(e.target.value)} />
												</FormControl>
											</Box>
										)}
										{selectedLawOption === 'no' && (
											<Box mt={4}>
												<FormControl>
													<FormLabel>বিস্তারিত লিখুন - কেনো?</FormLabel>
													<Input placeholder="বিস্তারিত লিখুন" value={lawWhy} onChange={(e) => setLawWhy(e.target.value)} />
												</FormControl>
											</Box>
										)}
									</Box>

									{/* 7. Legal Actions */}
									<Box>
										<FormControl>
											<FormLabel fontWeight="bold">সরকার পক্ষ থেকে কোনো প্রতিকারমূলক/আইনি পদক্ষেপ নেওয়া হয়েছে কিনা?</FormLabel>
											<RadioGroup
												onChange={(e) => {
													setSelectedActionsOption(e); setActionsDetails('');
												}}
												value={selectedActionsOption}
											>
												<Stack spacing={4} flexDirection={'row'}>
													<Radio value="yes">হ্যাঁ</Radio>
													<Radio value="no">না</Radio>
													<Radio value="unknown">অজানা</Radio>
												</Stack>
											</RadioGroup>
											{selectedActionsOption === 'yes' && (
												<Box mt={4}>
													<FormLabel>বিস্তারিত লিখুন - মামলা বা অভিযোগের বৃত্তান্ত</FormLabel>
													<Textarea placeholder="বিস্তারিত লিখুন" value={actionsDetails} onChange={(e) => setActionsDetails(e.target.value)} />
												</Box>
											)}
										</FormControl>
									</Box>

									{/* 8. Incident Description */}
									<Box>
										<FormControl>
											<FormLabel fontWeight="bold">উল্লিখিত বিবরণের বাইরে ঘটনাটি নিয়ে যদি কিছু লিখতে চান</FormLabel>
											<Textarea placeholder="বিস্তারিত লিখুন" value={incidentDetails} onChange={(e) => setIncidentDetails(e.target.value)} />
										</FormControl>
									</Box>

									{/* 9. Evidence Upload */}
									<Box>
										<FormControl>
											<FormLabel fontWeight="bold">ঘটনার স্বপক্ষে কোন প্রমাণ (ছবি/ভিডিও/অডিও/PDF/Doc) থাকলে আপলোড করুন</FormLabel>

											{/* Custom File Input */}
											<Box position="relative" display="inline-block">
												<Input
													id="file-upload"
													type="file"
													onChange={handleFileChange}
													accept="application/pdf,video/*, audio/*, image/*, application/msword"
													variant="filled"
													multiple
													display="none"
												/>

												<HStack>
													<Button
														as="label"
														htmlFor="file-upload"
														bgColor="white"
														color="black"
														// variant="outline"
														// colorScheme="red"
														border="2px"
														borderColor="red"
														cursor="pointer"
														_hover={{ bg: "red.600", color: "white" }}
														borderRadius="lg"
														px={4}
														py={2}
													>
														ফাইল নির্বাচন করুন
													</Button>
													{/* {selectedFiles && ( */}
													<Text mt={2} fontSize="sm">
														{selectedFiles?.length > 0
															? `${selectedFiles.length} টি ফাইল নির্বাচিত`
															: "কোন ফাইল নির্বাচন করা হয়নি"}
													</Text>
													{/* )} */}
												</HStack>
											</Box>
										</FormControl>

										{error && <p style={{ color: 'red' }}>{error}</p>}

										{/* // for multiple file system */}
										<Grid
											templateRows='repeat(1, 1fr)'
											templateColumns='repeat(4, 1fr)'
										>
											{previews.map((file, index) => (
												<GridItem
													colSpan={[4, 2, 1]}
													p='2'
													key={index}
												>
													{
														file.type.includes('pdf') ? (
															<Box h={['200px', "300px", "400px", '500px']}>
																<iframe
																	src={file.url}
																	title={file.name}
																	width="100%"
																	height="100%"
																></iframe>
															</Box>
														) : file.type.includes('image') ? (
															<Box w={'100%'} mx={'auto'}>
																<img
																	src={file.url}
																	alt={file.name}
																	style={{ maxWidth: '100%', height: 'auto' }}
																/>
															</Box>
														) : (
															<video
																controls
																width="500"
																src={file.url}
															>
																Your browser does not support the video tag.
															</video>
														)
													}
												</GridItem>
											))}


										</Grid>

										{isLoading &&
											<Text
												// fontSize={["md", "lg", "xl"]}
												fontWeight="bold"
												color='black'
												// textAlign={'center'}
												m={2}
											>
												<Spinner size="sm" mr={2} />
												ফাইল আপলোড হচ্ছে...
											</Text>
										}

										{/* // upload before submit  */}
										{/* { */}
										{/* !isLoading && */}
										<Button
											// bgColor={'red.600'}
											// color={'white'}
											// isLoading={isLoading}
											// loadingText=""
											colorScheme="red"
											mt={4}
											borderRadius={'lg'}
											// _hover={{ bg: "red", color: "white" }}
											// isDisabled={selectedFiles ? false : true}
											onClick={handleFileUpload}
										>
											{/* {uploadedFileIds ? "নির্বাচিত ফাইলগুলো আপলোডের জন্য এখানে চাপুন" : "আরও আপলোড"} */}
											নির্বাচিত ফাইলগুলো আপলোডের জন্য এখানে চাপুন
										</Button>
										{/* } */}

										<Box pt={4}>
											{uploadedFileIds.length > 0 && (
												<h3>আরও ফাইল যোগ করার জন্য আবার ফাইল পছন্দ করুন</h3>
											)}
										</Box>
									</Box>

									{/* links  */}
									<Box>
										<FormControl>
											<FormLabel fontWeight="bold">ঘটনা সংক্রান্ত কোন URL / লিঙ্ক থাকলে আপলোড করুন</FormLabel>
											<VStack spacing={4} align="start">
												{/* <HStack width="100%"> */}
												<Input
													type="url"
													// placeholder="লিঙ্ক লেখার পর যোগ করতে পাশের বাটন টি চাপুন।"
													placeholder="লিঙ্ক লেখার পর যোগ করতে নিচের বাটন টি চাপুন।"
													value={link}
													onChange={(e) => setLink(e.target.value)}
												// width="100%"
												/>
												<Button colorScheme="red" onClick={handleAddLink}>
													আরও URL / লিঙ্ক যোগ করতে এখানে চাপুন
												</Button>
												{/* </HStack> */}
												<VStack align="start" spacing={2} width="100%">
													{links.map((l, index) => (
														// <HStack key={index} justifyContent="space-between" width="300px">
														<HStack key={index} justifyContent="space-between" width="100%">
															<Text>{l}</Text>
															<Button size="xs" colorScheme="red" onClick={() => handleDeleteLink(index)}>
																Delete
															</Button>
														</HStack>
													))}
												</VStack>
											</VStack>
										</FormControl>
									</Box>

									{/* 8. Incident Info Giver */}
									<FormControl>
										<FormLabel fontWeight="bold">তথ্য প্রদানকারী যদি অন্য কারোর জন্য এই ঘটনা লিপিবদ্ধ করে, সেক্ষেত্রে তথ্য প্রদানকারীর পরিচয়</FormLabel>
										<Input
											placeholder="নাম লিখুন"
											value={incidentInfoGiver.name}
											onChange={(e) => setIncidentInfoGiver((prev) => ({
												...prev,
												name: e.target.value,
											}))
											}
										/>
										<Input
											marginY={2}
											placeholder="বয়স লিখুন"
											value={incidentInfoGiver.age}
											onChange={(e) => setIncidentInfoGiver((prev) => ({
												...prev,
												age: e.target.value,
											}))
											}
										/>
										<Input
											placeholder="লিঙ্গ লিখুন"
											value={incidentInfoGiver.gender}
											onChange={(e) => setIncidentInfoGiver((prev) => ({
												...prev,
												gender: e.target.value,
											}))
											}
										/>
										<Input
											marginY={2}
											placeholder="জেলা লিখুন"
											value={incidentInfoGiver.district}
											onChange={(e) => setIncidentInfoGiver((prev) => ({
												...prev,
												district: e.target.value,
											}))
											}
										/>
										<Input
											placeholder="উপজেলা লিখুন"
											value={incidentInfoGiver.upazila}
											onChange={(e) => setIncidentInfoGiver((prev) => ({
												...prev,
												upazila: e.target.value,
											}))
											}
										/>
										<Input
											marginY={2}
											placeholder="গ্রাম লিখুন"
											value={incidentInfoGiver.village}
											onChange={(e) => setIncidentInfoGiver((prev) => ({
												...prev,
												village: e.target.value,
											}))
											}
										/>
										<Input
											placeholder="ফোন নাম্বার লিখুন"
											value={incidentInfoGiver.phone}
											onChange={(e) => setIncidentInfoGiver((prev) => ({
												...prev,
												phone: e.target.value,
											}))
											}
										/>
										<Input
											marginY={2}
											placeholder="পেশা লিখুন"
											value={incidentInfoGiver.profession}
											onChange={(e) => setIncidentInfoGiver((prev) => ({
												...prev,
												profession: e.target.value,
											}))
											}
										/>
									</FormControl>

									{/* Submit Button */}
									{locationDistrict != '' && locationUpazila != '' && locationVillage != '' &&
									<Button bgColor={'red.600'} color={'white'} onClick={() => handleSubmit()}>
										জমা দিন
									</Button>|| 			
											<Text
												fontSize={["lg", "lg", "2xl"]}
												fontWeight="bold"
												color='black'
												textAlign={'center'}
												mb={2}
												pt={4}
											>
												{"ভুক্তভোগীর নাম, উপজেলা ও গ্রাম যোগ করুন যাতে জমা দিন বাটনটি চালু হয়"}
											</Text>
									}
								</VStack>
								{/* </Box> */}
							</Form>
						</Box>
					</Box >
				}
			</Box>
		</Layout >
	);
};

export default Incident;
