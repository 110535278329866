import React, {
	useEffect,
	useState,
} from 'react';
import {
	Box,
	Button,
	Checkbox,
	CheckboxGroup,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	HStack,
	Input,
	Select,
	Spinner,
	Stack,
	Text,
	Textarea,
	VStack,
} from '@chakra-ui/react';
import { Form } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
// import { CloseIcon } from '@chakra-ui/icons';

import Layout from '../Layout/Layout';
// import DescriptionCards from '../Components/DescriptionCards';
import { green_form_instructionText, welcomeText, } from '../homeDynamicTexts';

import { selectToken, selectDataBlueArchive, selectDataFileArchive } from '../Redux/Reducer';
import { archiveDataBlueSubmit, archiveFileSubmit } from '../Redux/Thunk/ArchiveUpload';
import categoryOfData2 from '../Components/categoryOfData2';


const IncidentGreen = () => {
	const dispatch = useDispatch();

	const token = useSelector(state => selectToken(state));
	// const token = "session_6752bf12a29fd875845db47c_1cecfb4d-3379-4722-8285-e362fad5bfad";
	// const profile = useSelector(state => selectProfile(state));
	const dataFile = useSelector(state => selectDataFileArchive(state));
	// console.log(dataFile);
	const data = useSelector(state => selectDataBlueArchive(state));
	// const history = useSelector(state => selectHistoryArchive(state));
	// const userName = useSelector(state => selectUserName(state));


	// tags box
	const [selectedTag, setSelectedTag] = useState("");
	const [selectedBroadCategory, setSelectedBroadCategory] = useState("");
	const [selectedSpecificCategories, setSelectedSpecificCategories] = useState([]);
	const [otherCategoryValue, setOtherCategoryValue] = useState("");
	const [selectedBroadContexts, setSelectedBroadContexts] = useState([]);
	const [contextSelections, setContextSelections] = useState({});
	const [otherContextValue, setOtherContextValue] = useState({});
	// console.log(contextSelections);
	// console.log(otherContextValue);

	const broadCategoryOptions = Object.keys(categoryOfData2.broad_category_of_offense);
	const specificCategoryOptions =
		selectedBroadCategory &&
		categoryOfData2.broad_category_of_offense[selectedBroadCategory]?.specific_category_of_offense;
	const broadContextOptions =
		selectedBroadCategory &&
		categoryOfData2.broad_category_of_offense[selectedBroadCategory]?.["broad_context(incident/victim)"];
	const handleTagChange = (e) => {
		setSelectedTag(e.target.value);
		setSelectedBroadCategory("");
		setSelectedSpecificCategories([]);
		setSelectedBroadContexts([]);
		// setSelectedSpecificContexts([]);
		// setSelectedMinorityType("");
	};
	const handleBroadCategoryChange = (e) => {
		setSelectedBroadCategory(e.target.value);
		setSelectedSpecificCategories([]);
		setSelectedBroadContexts([]);
		// setSelectedSpecificContexts([]);
		// setSelectedMinorityType("");
	};
	const handleSpecificCategoryChange = (values) => {
		setSelectedSpecificCategories(values);
		setSelectedBroadContexts([]);
	};
	const handleBroadContextChange = (values) => {
		setSelectedBroadContexts(values);
		// setSelectedSpecificContexts([]);
		// setSelectedMinorityType("");
	};
	const handleSpecificContextChange = (contextKey, values = [], subcategory) => {
		setContextSelections((prev) => {
			const isSingleSelectionCategory = contextKey === "সংখ্যালঘু";

			let updatedSelections;

			// Handle categories with subcategories separately
			if (subcategory) {
				updatedSelections = {
					...prev,
					[contextKey]: {
						...prev[contextKey],
						// [subcategory]: Array.isArray(values) ? values : [], // Ensure array for subcategories
						[subcategory]: isSingleSelectionCategory
							? Array.isArray(values) ? values.slice(-1) : [] // Keep only the last selection
							: Array.isArray(values) ? values : [],
					},
				};
			} else {
				updatedSelections = isSingleSelectionCategory
					? {
						...prev,
						[contextKey]: Array.isArray(values) ? values.slice(-1)[0] : values, // Single selection for main category
						// [contextKey]: Array.isArray(values) ? values.slice(-1) : [], // Single selection
					}
					: {
						...prev,
						[contextKey]: Array.isArray(values) ? values : [], // Multiple selection for main category
					};
			}

			// Remove "অন্যান্য" from `otherContextValue` if unchecked
			const keyForOtherContext = subcategory ? `${contextKey}-${subcategory}` : contextKey;
			if (!values.includes("অন্যান্য") && otherContextValue[keyForOtherContext]) {
				setOtherContextValue((prevOtherContextValue) => {
					const { [keyForOtherContext]: _, ...rest } = prevOtherContextValue;
					return rest;
				});
			}

			return updatedSelections;
		});
	};
	const handleOtherContextChange = (key, value) => {
		setOtherContextValue((prev) => ({
			...prev,
			[key]: value,
		}));
	};


	// form data
	const [sourceDetails, setSourceDetails] = useState('');
	const [datePublish, setDatePublish] = useState('');
	const [dateIncident, setDateIncident] = useState('');
	// const [location, setLocation] = useState('');
	const [locationDistrict, setLocationDistrict] = useState('');
	const [locationUpazila, setLocationUpazila] = useState('');
	const [locationVillage, setLocationVillage] = useState('');
	const [reporterName, setReporterName] = useState('');
	const [reporterPhone, setReporterPhone] = useState('');

	// file upload 
	// const [uploadFlag, setUploadFlag] = useState(false);
	// for multiple file system
	const [selectedFiles, setSelectedFiles] = useState(null);
	const [previews, setPreviews] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	useEffect(() => {
		// Cleanup the object URLs when component unmounts or previews change
		return () => {
			previews.forEach(file => URL.revokeObjectURL(file.url));
		};
	}, [previews]);
	const handleFileChange = (event) => {
		const files = Array.from(event.target.files);

		const maxSize = 2 * 1024 * 1024 * 1024; // 2GB in bytes
		// const maxSize = 1 * 1024 * 1024 * 1024; // 1GB in bytes

		const validFiles = [];
		const filePreviews = [];
		files.forEach(file => {
			if (file.size > maxSize) {
				setError(`File "${file.name}" exceeds the 2GB size limit.`);
			} else {
				validFiles.push(file);
				const fileURL = URL.createObjectURL(file);
				filePreviews.push({ name: file.name, type: file.type, url: fileURL });
			}
		});
		if (validFiles.length > 0) {
			setSelectedFiles(validFiles);
			setPreviews(filePreviews);
			setError(''); // Clear any previous errors
		}
	};
	const [uploadedFileIds, setUploadedFileIds] = useState([]);  // To store response IDs
	// console.log(uploadedFileIds);
	// Monitor changes in dataFile to capture new file IDs
	useEffect(() => {
		if (dataFile && dataFile.ivpfile_id) {
			// Update state with the new file ID
			/*
			setUploadedFileIds((prevIds) => {
				if (!prevIds.includes(dataFile.ivpfile_id)) {
					return [...prevIds, dataFile.ivpfile_id];
				}
				return prevIds;
			});
			*/
			setUploadedFileIds((prevFiles) => {
				// Check if the file ID already exists in the previous state
				if (!prevFiles.some(file => file.id === dataFile.ivpfile_id)) {
					// If not, add the new file object to the state
					return [...prevFiles, { id: dataFile.ivpfile_id, mime_type: dataFile.mime_type }];
				}
				// If it exists, return the previous state unchanged
				return prevFiles;
			});
		}
	}, [dataFile]); // Trigger effect whenever dataFile changes
	const handleFileUpload = async (event) => {
		event.preventDefault();

		if (!selectedFiles || selectedFiles.length === 0) {
			toast.error("Please select at least one file to upload.");
			return;
		}

		setIsLoading(true); // Start loading before the upload process begins
		setError(''); // Clear any existing errors

		try {
			const uploadResults = await Promise.all(
				selectedFiles.map(async (file) => {
					const formData = new FormData();
					formData.append('ivpfile', file);

					try {
						await dispatch(archiveFileSubmit(formData, token));
						// toast.success(`File ${file.name} uploaded successfully.`);
					} catch (error) {
						// console.error(`Error uploading file ${file.name}:`, error);
						throw new Error(`Failed to upload file ${file.name}.`);
					}
				})
			);

			console.log("All files uploaded:", uploadResults); // Optional: Debug upload results
		} catch (error) {
			setError(error.message || "An error occurred during file upload.");
			toast.error(error.message);
		} finally {
			// setSelectedFiles(null);
			// setPreviews([]);
			// await new Promise((resolve) => setTimeout(resolve, 10000));
			setIsLoading(false); // Stop loading after all uploads complete or an error occurs
		}
	};

	// create as like json file to send data submit
	const createJsonData = () => {
		// for specific broad category
		let specificCategory = [...selectedSpecificCategories];
		// Check if "অন্যান্য" exists and replace it with the formatted value
		const othersIndex = specificCategory.findIndex((item) => item === "অন্যান্য");
		if (othersIndex !== -1) {
			// Replace "অন্যান্য" with "অন্যান্য-(the value of otherCategoryValue)"
			specificCategory[othersIndex] = `অন্যান্য-${otherCategoryValue}`;
		}

		// for context
		const contextSet = Object.keys(contextSelections).reduce((acc, key) => {
			const selection = contextSelections[key];

			if (typeof selection === 'object' && !Array.isArray(selection)) {
				// Handle nested objects like "সংখ্যালঘু"
				acc[key] = Object.keys(selection).reduce((subAcc, subKey) => {
					if (subKey === "অন্যান্য" && otherContextValue[`${key}-অন্যান্য`]) {
						subAcc[subKey] = `অন্যান্য-${otherContextValue[`${key}-অন্যান্য`]}`; // Store as a string
					} else {
						subAcc[subKey] = Array.isArray(selection[subKey]) ? selection[subKey][0] : selection[subKey]; // Ensure values are strings
					}
					return subAcc;
				}, {});
			} else if (Array.isArray(selection)) {
				// Handle arrays like "১৯৭১/মুক্তিযুদ্ধ"
				acc[key] = selection.map(item =>
					item === "অন্যান্য" && otherContextValue[key]
						? `অন্যান্য-${otherContextValue[key]}`
						: item
				);
			} else {
				acc[key] = selection; // Default fallback
			}

			return acc;
		}, {});


		return {
			data_type: 'green',
			complex_category: {
				tag: selectedTag,
				broad_category: selectedBroadCategory,
				// specific_category: selectedSpecificCategories,
				specific_category: specificCategory,
				// context: contextSelections,
				context: contextSet,
			},
			green_fifth: {
				information_source: sourceDetails,
				publication_date: datePublish,
				date_of_incident: dateIncident,
				place_of_occurrence: {
					district: locationDistrict,
					upazila: locationUpazila,
					village: locationVillage,
					phone: "",
				},
				files: uploadedFileIds,
				// files: [{ "id": "file12345", "mime_type": "application/pdf" }],
				reporter_info: {
					name: reporterName,
					phone: reporterPhone,
				}
			},
		};
	};
	// const jsonData = createJsonData();
	// console.log(jsonData);

	const handleSubmit = event => {
		// event.preventDefault();

		// if (contextSelections.length > 0) {
		// if (contextSelections !== undefined) {
		if (Object.keys(contextSelections).length > 0) {

			const jsonData = createJsonData();
			// console.log(jsonData);
			dispatch(archiveDataBlueSubmit(jsonData, token));

			// setUploadFlag(true);
		}
		else {
			toast.error('Please select tags.');
		}
	};

	useEffect(() => {
		if (dataFile.success) {
			toast.success(dataFile.success);
			// setUploadFlag(false);
		}
		if (dataFile.error.message) {
			toast.error(dataFile.error.message);
			// setUploadFlag(false);
		}
	}, [dataFile.error.message, dataFile.success, dispatch]);

	useEffect(() => {
		if (data.success) {
			toast.success(data.success);
			// setUploadFlag(false);

			setSelectedTag("");
			setSelectedBroadCategory("");
			setSelectedSpecificCategories([]);
			setOtherCategoryValue("");
			setSelectedBroadContexts([]);
			setContextSelections({});
			setOtherContextValue({});
			setSourceDetails('');
			setDatePublish('');
			setDateIncident('');
			setLocationDistrict('');
			setLocationUpazila('');
			setLocationVillage('');
			setReporterName('');
			setReporterPhone('');
			setSelectedFiles(null);
			setPreviews([]);
			setError('');
			setUploadedFileIds([]);
		}
		if (data.error.message) {
			toast.error(data.error.message);
			// setUploadFlag(false);
		}
	}, [data.error.message, data.success, dispatch]);


	function validateNestedData(data) {
		if (data === undefined || data === null) {
			return false; // Invalid if data is null or undefined
		}

		if (typeof data === 'object') {
			if (Array.isArray(data)) {
				// Check if at least one valid value exists in the array
				return data.some(item => validateNestedData(item));
			} else {
				// Check if at least one valid value exists in the object
				return Object.values(data).some(value => validateNestedData(value));
			}
		}

		// For primitive values, check if they are valid (not empty or whitespace)
		return data.toString().trim() !== '';
	};


	//<Box maxW="1200px" mx="auto" px={4} py={8}></Box>
	return (
		<Layout>
			<Text
				fontSize={["lg", "lg", "2xl"]}
				fontWeight="bold"
				color='black'
				textAlign={'center'}
				mb={2}
				pt={4}
			>
				{welcomeText}
			</Text>

			<Text
				// fontSize={["lg", "lg", "2xl"]}
				fontSize={["xs", "xs", "sm"]}
				fontWeight="bold"
				color='black'
				// textAlign={'center'}
				// mb={2}
				px={4}
			>
				{green_form_instructionText}
			</Text>

			<Box maxW="container.lg" mx="auto" px={4} py={8}>
				<Box
					// rowSpan={3}
					// colSpan={[5, 5, 3, 3, 3]}
					boxShadow='dark-lg'
					p={[3, 6]}
					rounded='md'
					w={'100%'}
					mb={5}
				>
					<Box maxW={"100%"} mx="auto" fontSize={["xs", "sm", "md"]}>
						{/* Select Tag */}
						<Stack gap={[2, 5]} marginYZ={3} direction={['column', 'column', 'row',]}>
							<Text
								// fontSize={["md", "md", "lg"]}
								fontWeight="semibold"
								color='black'
								textAlign={'left'}
								w={["100%", "100%", "35%"]}
							>
								ট্যাগ
							</Text>

							<Select placeholder="ট্যাগ নির্বাচন করুন" value={selectedTag} onChange={handleTagChange} mb={4} w={["100%", "100%", "65%"]}>
								{categoryOfData2.tags.map((tag, index) => (
									<option key={index} value={tag}>
										{tag}
									</option>
								))}
							</Select>
						</Stack>

						{/* Select Broad Category */}
						{selectedTag && (
							<Stack gap={[2, 5]} marginYZ={3} direction={['column', 'column', 'row',]}>
								<Text
									// fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
									w={["100%", "100%", "35%"]}
								>
									অপরাধের ধরন ?
								</Text>

								<Select
									placeholder="অপরাধের ধরন নির্বাচন করুন"
									value={selectedBroadCategory}
									onChange={handleBroadCategoryChange}
									mb={4}
									w={["100%", "100%", "65%"]}
								>
									{broadCategoryOptions.map((category, index) => (
										<option key={index} value={category}>
											{category}
										</option>
									))}
								</Select>
							</Stack>
						)}

						{/* Select Specific Category */}
						{selectedBroadCategory && specificCategoryOptions && (
							<Stack gap={[2, 5]} marginYZ={3} direction={['column', 'column', 'row',]}>
								<Text
									// fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
									w={["100%", "100%", "35%"]}
								>
									অপরাধের ধরন আরেকটু নির্দিষ্ট করুন
								</Text>

								<CheckboxGroup value={selectedSpecificCategories} onChange={handleSpecificCategoryChange} w={["100%", "100%", "65%"]}>
									<Stack spacing={2} mb={4}>
										{specificCategoryOptions.map((option, index) => (
											<Checkbox key={index} value={option}>
												{option}
											</Checkbox>
										))}
										{/* Input field for "অন্যান্য" */}
										{selectedSpecificCategories.some((item) => item.startsWith("অন্যান্য")) && (
											<Input
												placeholder="অপরাধের ধরন লিখুন"
												value={otherCategoryValue}
												// onChange={handleOtherCategoryChange}
												onChange={(e) => setOtherCategoryValue(e.currentTarget.value)}
												mt={2}
											/>
										)}
									</Stack>
								</CheckboxGroup>
							</Stack>
						)}

						{/* Select Broad Context */}
						{selectedSpecificCategories.length > 0 && broadContextOptions && (
							<Stack gap={[2, 5]} marginYZ={3} direction={['column', 'column', 'row',]}>
								<Text
									// fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
									w={["100%", "100%", "35%"]}
								>
									অপরাধের প্রেক্ষাপট
								</Text>

								<CheckboxGroup value={selectedBroadContexts} onChange={handleBroadContextChange} w={["100%", "100%", "65%"]}>
									<Stack spacing={2} mb={4}>
										{broadContextOptions.map((option, index) => (
											<Checkbox key={index} value={option}>
												{option}
											</Checkbox>
										))}
									</Stack>
								</CheckboxGroup>
							</Stack>
						)}

						{/* Select Specific Context */}
						{
							selectedBroadContexts?.length > 0 &&
							<Stack>
								<Text
									// fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									color='black'
									textAlign={'left'}
									mb={2}
								>
									অপরাধের প্রেক্ষাপট আরেকটু নির্দিষ্ট করুন
								</Text>

								<Stack gap={[2, 5]} marginYZ={3} direction={['column', 'column', 'row',]}>

									{selectedBroadContexts.map((contextKey) => {
										const specificContextOptions =
											categoryOfData2.broad_category_of_offense[selectedBroadCategory]?.[
											"specific_context(incident/victim)"
											][contextKey] || {};

										return (
											<Box key={contextKey}>
												<Text fontWeight="semibold">
													{/* নির্দিষ্ট প্রেক্ষাপট ({contextKey}) */}
													{contextKey}
												</Text>

												{/* Main Category */}
												{Array.isArray(specificContextOptions) && (
													<CheckboxGroup
														value={contextSelections[contextKey] || []}
														onChange={(values) => handleSpecificContextChange(contextKey, values, '')}
													>
														<Stack>
															{specificContextOptions.map((option) => (
																<Checkbox key={option} value={option}>
																	{option}
																</Checkbox>
															))}
															{/* Input for "অন্যান্য" */}
															{contextSelections[contextKey]?.includes("অন্যান্য") && (
																<Input
																	placeholder="প্রেক্ষাপটের ধরন লিখুন"
																	value={otherContextValue[contextKey] || ""}
																	onChange={(e) =>
																		handleOtherContextChange(contextKey, e.target.value)
																	}
																	mt={2}
																/>
															)}
														</Stack>
													</CheckboxGroup>
												)}

												{/* Subcategories */}
												{!Array.isArray(specificContextOptions) &&
													Object.entries(specificContextOptions).map(
														([subcategory, options], index) => (
															<Box key={index}>
																<Text fontWeight="bold">{subcategory}</Text>
																<CheckboxGroup
																	value={
																		Array.isArray(contextSelections[contextKey]?.[subcategory])
																			? contextSelections[contextKey][subcategory]
																			: []
																	}
																	onChange={(values) =>
																		handleSpecificContextChange(contextKey, values, subcategory)
																	}
																>
																	<Stack>
																		{options.map((option) => (
																			<Checkbox key={option} value={option}>
																				{option}
																			</Checkbox>
																		))}
																		{/* Input for "অন্যান্য" in subcategories */}
																		{contextSelections[contextKey]?.[subcategory]?.includes(
																			"অন্যান্য"
																		) && (
																				<Input
																					placeholder="প্রেক্ষাপটের ধরন লিখুন"
																					value={
																						otherContextValue[`${contextKey}-${subcategory}`] ||
																						""
																					}
																					onChange={(e) =>
																						handleOtherContextChange(
																							`${contextKey}-${subcategory}`,
																							e.target.value
																						)
																					}
																					mt={2}
																				/>
																			)}
																	</Stack>
																</CheckboxGroup>
															</Box>
														)
													)}
											</Box>
										);
									})}
								</Stack>
							</Stack>
						}
					</Box>
				</Box>

				{
					// (Object.keys(contextSelections).length > 0) &&
					validateNestedData(contextSelections) &&
					<Box
						boxShadow='dark-lg'
						p={[3, 6]}
						rounded='md'
						w={'100%'}
						fontSize={["xs", "sm", "md"]}
					>
						{/* <Box marginTop={10}> */}
						{/* <Form onSubmit={handleSubmit}> */}
						<Form>
							{/* <Box maxW="800px" mx="auto" p={5} boxShadow="md" rounded="lg" bg="white"> */}
							<VStack spacing={5} align="stretch">
								{/* source */}
								<FormControl>
									<FormLabel>তথ্যের উৎস/নাম (গণমাধ্যম/সোশ্যাল মিডিয়া /ব্যাক্তিগত যোগাযোগ)</FormLabel>
									<Textarea
										// marginY={2}
										// placeholder="উপজেলা লিখুন"
										value={sourceDetails}
										onChange={(e) => setSourceDetails(e.target.value)}
									/>
								</FormControl>

								{/* Date */}
								<FormControl>
									<FormLabel>তথ্য প্রকাশের তারিখ</FormLabel>
									<Input type="date" value={datePublish} onChange={(e) => setDatePublish(e.target.value)} />
								</FormControl>

								{/* Date */}
								<FormControl>
									<FormLabel>ঘটনা যেদিন ঘটেছে, সেটার তারিখ</FormLabel>
									<Input type="date" value={dateIncident} onChange={(e) => setDateIncident(e.target.value)} />
								</FormControl>

								{/* Location */}
								<FormControl>
									<FormLabel>ঘটনার স্থান (জেলা,উপজেলা,গ্রাম)</FormLabel>
									<Input
										placeholder="জেলা নাম লিখুন"
										value={locationDistrict}
										onChange={(e) => setLocationDistrict(e.target.value)}
									/>
									<Input
										marginY={2}
										placeholder="উপজেলা নাম লিখুন"
										value={locationUpazila}
										onChange={(e) => setLocationUpazila(e.target.value)}
									/>
									<Input
										placeholder="গ্রাম নাম লিখুন"
										value={locationVillage}
										onChange={(e) => setLocationVillage(e.target.value)}
									/>
								</FormControl>

								{/*  Evidence Upload */}
								<Box>
									<FormControl>
										<FormLabel fontWeight="bold">ঘটনার স্বপক্ষে কোন প্রমাণ (ছবি/ভিডিও/অডিও/PDF/Doc) থাকলে আপলোড করুন</FormLabel>

										{/* Custom File Input */}
										<Box position="relative" display="inline-block">
											<Input
												id="file-upload"
												type="file"
												onChange={handleFileChange}
												accept="application/pdf,video/*, audio/*, image/*, application/msword"
												variant="filled"
												multiple
												display="none"
											/>

											<HStack>
												<Button
													as="label"
													htmlFor="file-upload"
													bgColor="white"
													color="black"
													// variant="outline"
													// colorScheme="red"
													border="2px"
													borderColor="red"
													cursor="pointer"
													_hover={{ bg: "red.600", color: "white" }}
													borderRadius="lg"
													px={4}
													py={2}
												>
													ফাইল নির্বাচন করুন
												</Button>
												{/* {selectedFiles && ( */}
												<Text mt={2} fontSize="sm">
													{selectedFiles?.length > 0
														? `${selectedFiles.length} টি ফাইল নির্বাচিত`
														: "কোন ফাইল নির্বাচন করা হয়নি"}
												</Text>
												{/* )} */}
											</HStack>
										</Box>
									</FormControl>

									{error && <p style={{ color: 'red' }}>{error}</p>}

									{/* // for multiple file system */}
									<Grid
										templateRows='repeat(1, 1fr)'
										templateColumns='repeat(4, 1fr)'
									>
										{previews.map((file, index) => (
											<GridItem
												colSpan={[4, 2, 1]}
												p='2'
												key={index}
											>
												{
													file.type.includes('pdf') ? (
														<Box h={['200px', "300px", "400px", '500px']}>
															<iframe
																src={file.url}
																title={file.name}
																width="100%"
																height="100%"
															></iframe>
														</Box>
													) : file.type.includes('image') ? (
														<Box w={'100%'} mx={'auto'}>
															<img
																src={file.url}
																alt={file.name}
																style={{ maxWidth: '100%', height: 'auto' }}
															/>
														</Box>
													) : (
														<video
															controls
															width="500"
															src={file.url}
														>
															Your browser does not support the video tag.
														</video>
													)
												}
											</GridItem>
										))}


									</Grid>

									{isLoading &&
										<Text
											// fontSize={["md", "lg", "xl"]}
											fontWeight="bold"
											color='black'
											// textAlign={'center'}
											m={2}
										>
											<Spinner size="sm" mr={2} />
											ফাইল আপলোড হচ্ছে...
										</Text>
									}

									{/* // upload before submit  */}
									{/* { */}
									{/* isLoading && */}
									<Button
										// bgColor={'red.600'}
										// color={'white'}
										// isLoading={isLoading}
										// loadingText=""
										colorScheme="red"
										mt={4}
										borderRadius={'lg'}
										// _hover={{ bg: "red", color: "white" }}
										isDisabled={selectedFiles ? false : true}
										onClick={handleFileUpload}
									>
										{/* {uploadedFileIds ? "নির্বাচিত ফাইলগুলো আপলোডের জন্য এখানে চাপুন" : "আরও আপলোড"} */}
										নির্বাচিত ফাইলগুলো আপলোডের জন্য এখানে চাপুন
									</Button>
									{/* } */}

									<Box pt={4}>
										{uploadedFileIds.length > 0 && (
											<h3>আরও ফাইল যোগ করার জন্য আবার ফাইল পছন্দ করুন</h3>
										)}
									</Box>
								</Box>

								{/* Reporter */}
								<Box>
									<FormControl>
										<FormLabel fontWeight="bold">রিপোর্টারের (প্রতিবেদকের?) তথ্য</FormLabel>
										<Input
											// marginY={2}
											placeholder="নাম"
											value={reporterName}
											onChange={(e) => setReporterName(e.target.value)}
										/>
										<Input
											marginY={2}
											placeholder="ফোন নং"
											value={reporterPhone}
											onChange={(e) => setReporterPhone(e.target.value)}
										/>
									</FormControl>
								</Box>

								{/* Submit Button */}
								<Button bgColor={'red.600'} color={'white'} onClick={() => handleSubmit()}>
									জমা দিন
								</Button>
							</VStack>
							{/* </Box> */}
						</Form>
						{/* </Box> */}
					</Box >
				}
			</Box>
		</Layout >
	);
};

export default IncidentGreen;
