import React, { useEffect, useState } from "react";
import { Box, Button, Flex, IconButton, Text, HStack } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import {
	selectActionBlueDatas,
	selectBlueFormListDocuments,
	selectDownloadFormFile,
	selectInfoFile,
	selectToken,
} from "../../Redux/Reducer";
import {
	deleteBlueData,
	processFileDownloads,
	getBlueFormListDocumentsData,
	getBlueFormSearchResult,
	getFileInfo,
} from "../../Redux/Thunk/BlueFormList";
import Layout from "../../Layout/Layout";

import SearchOptions from "./SearchOptions";
import LibraryResources from "./LibraryResources";
import toast from "react-hot-toast";
import EditModal from "./EditModal";
import FilePreviewModal from "../../Components/FilePreviewModal";

export default function BlueFormList() {
	// const { innerHeight } = window;
	const dispatch = useDispatch();

	const token = useSelector((state) => selectToken(state));
	// const token = "session_6752bf12a29fd875845db47c_1cecfb4d-3379-4722-8285-e362fad5bfad";
	const data = useSelector((state) => selectBlueFormListDocuments(state));
	const dataFileInfo = useSelector((state) => selectInfoFile(state));
	const dataFileDownload = useSelector((state) => selectDownloadFormFile(state));
	// const dataFile = useSelector(state => selectDataFileArchive(state));
	const dataDelete = useSelector((state) => selectActionBlueDatas(state));
	// console.log(data.blueFormListDocuments.list);
	// const userName = useSelector(state => selectUserName(state));

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(50); // Default to 5 items per page

	const [reloadFlag, setReloadFlag] = useState(0); // Default to 5 items per page
	// initial data load and after recall
	useEffect(() => {
		dispatch(
			getBlueFormListDocumentsData(
				(currentPage - 1) * itemsPerPage,
				itemsPerPage,
				token,
			),
		);
	}, [dispatch, token, currentPage, itemsPerPage, reloadFlag]);

	const [tableData, setTableData] = useState([]);

	// data set
	useEffect(() => {
		if (data && data.blueFormListDocuments) {
			setTableData(data.blueFormListDocuments.list);
		}
	}, [data]);

	// // for search
	const [selectedTagOption, setSelectedTagOption] = useState("");
	const optionsTag = [
		{ value: "মানবাধিকার", label: "মানবাধিকার" },
		{ value: "কূটনৈতিক", label: "কূটনৈতিক" },
		{ value: "ইতিহাস", label: "ইতিহাস" },
		{ value: "রাজনৈতিক", label: "রাজনৈতিক" },
		{ value: "আইন", label: "আইন" },
	];
	const handleTagChange = (event) => {
		setSelectedTagOption(event.target.value);
	};
	const [selectedBoardCategoryOption, setSelectedBoardCategoryOption] = useState("");
	const optionsBoardCategory = [
		{ value: "সহিংসতা", label: "সহিংসতা" },
		{ value: "হুমকি/ভয়ভীতি প্রদর্শন", label: "হুমকি/ভয়ভীতি প্রদর্শন" },
		{ value: "হয়রানি/বৈষম্য", label: "হয়রানি/বৈষম্য" },
		{ value: "উস্কানি", label: "উস্কানি" },
	];
	const handleBoardCategoryChange = (event) => {
		setSelectedBoardCategoryOption(event.target.value);
	};
	const [selectedSpecificCategoryOptions, setSelectedSpecificCategoryOptions] = useState([]);
	const optionsSpecificCategory = [
		{ value: "হত্যা", label: "হত্যা" },
		{ value: "ধর্ষণ", label: "ধর্ষণ" },
		{ value: "অগ্নিসংযোগ", label: "অগ্নিসংযোগ" },
		{ value: "সম্পত্তি বিনাশ", label: "সম্পত্তি বিনাশ" },
		{ value: "নির্যাতন", label: "নির্যাতন" },
		{ value: "গুম/অপহরণ", label: "গুম/অপহরণ" },
		{ value: "বিচার বহির্ভূত হত্যা", label: "বিচার বহির্ভূত হত্যা" },
		{ value: "স্থাপনা", label: "স্থাপনা" },
		{ value: "চুরি/ডাকাতি/লুটপাট", label: "চুরি/ডাকাতি/লুটপাট" },
		{
			value: "জোরপূর্বক চাকরীচ্যুত করা বা কর্তব্যে বাঁধা প্রদান",
			label: "জোরপূর্বক চাকরীচ্যুত করা বা কর্তব্যে বাঁধা প্রদান",
		},
		{ value: "মামলা", label: "মামলা" },
		{ value: "সমাবেশে বাঁধা প্রদান", label: "সমাবেশে বাঁধা প্রদান" },
		{
			value: "ধর্মীয় আচার অনুষ্ঠান পালনে বাঁধাপ্রদান/অনধিকার চর্চা",
			label: "ধর্মীয় আচার অনুষ্ঠান পালনে বাঁধাপ্রদান/অনধিকার চর্চা",
		},
		{ value: "মত প্রকাশের স্বাধীনতা", label: "মত প্রকাশের স্বাধীনতা" },
		{ value: "অন্যান্য", label: "অন্যান্য" },
	];
	const handleCheckboxSpecificCategoryChange = (value) => {
		setSelectedSpecificCategoryOptions((prev) =>
			prev.includes(value)
				? prev.filter((item) => item !== value)
				: [...prev, value],
		);
	};
	const data2 = {
		"broad_context(incident/victim)": [
			"সংখ্যালঘু",
			"১৯৭১/মুক্তিযুদ্ধ",
			"রাজনৈতিক",
			"আইনশৃঙ্খলা বাহিনী",
			"গণমাধ্যম",
			"বিচারব্যবস্থা",
			"সংস্কৃতি",
		],
		"specific_context(incident/victim)": {
			সংখ্যালঘু: {
				"ধর্ম ভিত্তিক": [
					"মুসলিম",
					"আহমাদিয়া",
					"সুফী/মাজার কেন্দ্রিক বিশ্বাস",
					"হিন্দু",
					"বৌদ্ধ",
					"খ্রিস্টান",
					"নাস্তিক",
				],
				"জাতি ভিত্তিক": ["বাঙালি", "পাহাড়ি আদিবাসী", "সমতল আদিবাসী"],
				লিঙ্গভিত্তিক: ["LGBTQ", "নারী"],
				অন্যান্য: ["অন্যান্য"],
			},
			"১৯৭১/মুক্তিযুদ্ধ": [
				"মুক্তিযোদ্ধা এবং তাঁদের পরিবার",
				"বীরাঙ্গনা এবং তাঁদের পরিবার",
				"শহীদ পরিবার",
				"ICT- বিচারক এবং সাক্ষী",
				"একটিভিস্ট/লেখক/গবেষক",
				"স্থাপনা/ভাস্কর্য",
				"আর্কাইভ / জাদুঘর",
				"অন্যান্য",
			],
			রাজনৈতিক: ["আওয়ামিলীগ ও অঙ্গসংগঠন", "বিএনপি ও অঙ্গসংগঠন", "অন্যান্য"],
			"আইনশৃঙ্খলা বাহিনী": ["পুলিশ", "আর্মি", "আনসার", "BGB", "RAB"],
			গণমাধ্যম: ["স্থাপনা", "সংবাদকর্মী"],
			বিচারব্যবস্থা: ["বিচারক", "আইনজীবী", "সাক্ষী", "আসামী"],
			সংস্কৃতি: [
				"স্থাপনা / ভাস্কর্য",
				"শিল্পী/সাহিত্যিক/কর্মী",
				"আর্কাইভ",
				"অন্যান্য",
			],
		},
	};
	const [selectedBroad, setSelectedBroad] = useState([]);
	// console.log(selectedBroad);
	const [selectedSpecific, setSelectedSpecific] = useState({});
	// console.log(selectedSpecific);
	// const handleBroadSelect = (values) => {
	// 	setSelectedBroad(values);
	// 	// Reset specific selections if সংখ্যালঘু is deselected
	// 	if (!values.includes("সংখ্যালঘু")) {
	// 		setSelectedSpecific({});
	// 	}
	// };
	const handleBroadSelect = (value) => {
		setSelectedBroad((prev) => {
			const updatedSelection = prev.includes(value)
				? prev.filter((item) => item !== value) // Remove if already selected
				: [...prev, value]; // Add if not selected

			// Reset specific selections if "সংখ্যালঘু" is deselected
			if (!updatedSelection.includes("সংখ্যালঘু")) {
				setSelectedSpecific({});
			}

			return updatedSelection;
		});
	};
	const handleSpecificSelect = (context, subCategory, values) => {
		setSelectedSpecific((prev) => {
			// For nested contexts like "সংখ্যালঘু"
			if (typeof subCategory === "string") {
				return {
					...prev,
					[context]: {
						...(prev[context] || {}),
						[subCategory]: values,
					},
				};
			}

			// For flat contexts like "রাজনৈতিক"
			return {
				...prev,
				[context]: values,
			};
		});
	};
	const [startDate, setStartDate] = useState("1990-01-01");
	const currentDate = new Date();
	const formattedDate = currentDate.toISOString().split("T")[0];
	const [endDate, setEndDate] = useState(formattedDate);
	// create as like json file to send data submit
	const createJsonData = () => {
		return {
			complex_category: {
				tag: selectedTagOption,
				broad_category: selectedBoardCategoryOption,
				specific_category: selectedSpecificCategoryOptions,
				context: selectedSpecific,
			},
			start_time: startDate,
			end_time: endDate,
			skip: 0,
			limit: 50,
		};
	};
	// const jsonData = createJsonData();
	// console.log(jsonData);
	const handleSearch = () => {
		// dispatch(getSearchResult((currentPage - 1) * itemsPerPage, itemsPerPage, token, startDate, endDate, listSearchSelectedTags, listSearchSelectedEvents));
		// dispatch(getBlueFormSearchResult(1, 50, token, startDate, endDate, selectedTagOption, selectedBoardCategoryOption, selectedSpecificCategoryOptions, selectedSpecific));

		const jsonData = createJsonData();
		// console.log(jsonData);
		dispatch(getBlueFormSearchResult(jsonData, token));

		setCurrentPage(1);
		setItemsPerPage(50);
		// setStartDate("");
		// setEndDate("");
	};

	const filteredData = tableData;
	// pagination
	const handlePageChange = (page) => {
		if (page >= 1) {
			setCurrentPage(page);
		}
	};

	// delete
	const [deleteId, setDeleteId] = useState("");
	const handleDelete = (id) => {
		if (id) {
			// console.log(id);
			setDeleteId(id);

			dispatch(deleteBlueData(id, token));
		}
	};
	useEffect(() => {
		if (dataDelete.success) {
			toast.success(dataDelete.success);
			// dispatch(getListDocumentsData((currentPage - 1) * itemsPerPage, itemsPerPage, token));
			setTableData((prevData) =>
				prevData.filter((item) => item.id !== deleteId),
			);
			setDeleteId("");
		}
		if (dataDelete.error.message) {
			toast.error(dataDelete.error.message);
			setDeleteId("");
		}
	}, [dataDelete.error.message, dataDelete.success, deleteId, dispatch]);

	// edit
	const [isEditOpen, setIsEditOpen] = useState(false);
	const [incidentData, setIncidentData] = useState({});
	// console.log(incidentData);
	const handleEditOpen = (td) => {
		setIsEditOpen(true);
		setIncidentData(td);
	};
	const handleEditClose = () => {
		setIsEditOpen(false);
		setIncidentData({});
	};

	// DOWNLOAD single data file
	const handleDownload = (files) => {
		// console.log(files);
		if (files) {
			toast.success(
				"Your download request has been received and will be delivered soon.",
				{ duration: 5000 },
			);
			processFileDownloads(files, dispatch);
			// files.map((file, i) => (
			// 	dispatch(downloadFormFiles(file.id, token))
			// ))
		}
	};
	// toast
	useEffect(() => {
		if (dataFileDownload.error.message) {
			toast.error(dataFileDownload.error.message);
		}
	}, [dataFileDownload.error.message]);

	// file preview
	const [curFilename, setFileName] = useState("");
	const [curFileType, setFileType] = useState("");
	const [isFileViewOpen, setFileViewOpen] = useState(false);
	// const [previewFile, setPreviewFile] = useState([]);
	const onFileViewClose = () => {
		setFileViewOpen(false);
		setFileName('');
		setFileType('');
	};
	const handlePreview = (file, td) => {
		// setPreviewFile([]);
		// console.log(file);
		// console.log(td);

		if (file) {
			setFileName(file.id);
			// setPreviewFile(td);

			if (file?.mime_type) {
				const mimeToExtension = (mimeType) => {
					const mimeMap = {
						// Images
						"image/png": "png",
						"image/jpeg": "jpg",
						"image/jpg": "jpg",
						"image/gif": "gif",
						"image/bmp": "bmp",
						"image/webp": "webp",
						"image/svg+xml": "svg",
						"image/tiff": "tiff",
						// Text
						"text/plain": "txt",
						"text/html": "html",
						"text/css": "css",
						"text/javascript": "js",
						"text/csv": "csv",
						"text/markdown": "md",
						// Application
						"application/json": "json",
						"application/pdf": "pdf",
						"application/xml": "xml",
						"application/zip": "zip",
						"application/x-tar": "tar",
						"application/x-7z-compressed": "7z",
						"application/vnd.ms-excel": "xls",
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
							"xlsx",
						"application/msword": "doc",
						"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
							"docx",
						"application/vnd.ms-powerpoint": "ppt",
						"application/vnd.openxmlformats-officedocument.presentationml.presentation":
							"pptx",
						// Audio
						"audio/mpeg": "mp3",
						"audio/wav": "wav",
						"audio/ogg": "ogg",
						"audio/aac": "aac",
						"audio/flac": "flac",
						// Video
						"video/mp4": "mp4",
						"video/x-msvideo": "avi",
						"video/webm": "webm",
						"video/quicktime": "mov",
						"video/x-matroska": "mkv",
						// Fonts
						"font/ttf": "ttf",
						"font/woff": "woff",
						"font/woff2": "woff2",
						"application/vnd.ms-fontobject": "eot",
						// Compressed
						"application/x-rar-compressed": "rar",
						"application/gzip": "gz",
						"application/x-bzip2": "bz2",
						// Others
						"application/java-archive": "jar",
						"application/octet-stream": "bin",
						"application/x-msdownload": "exe",
					};

					return mimeMap[mimeType] || "unknown";
				};
				const mimeType = file.mime_type;
				const fileExtension = mimeToExtension(mimeType);
				setFileType(fileExtension);
				// setFileType(file.mime_type || 'png');

				setFileViewOpen(true);
			}
			else {
				dispatch(getFileInfo(file.id));
			}
		}
	};
	useEffect(() => {
		if (dataFileInfo.file_name) {
			// toast.error(dataFileDownload.error.message);
			const fileExtension = dataFileInfo.file_name.substring(dataFileInfo.file_name.lastIndexOf('.') + 1);
			setFileType(fileExtension);
			// console.log(fileExtension);
			setFileViewOpen(true);
		}
	}, [dataFileInfo]);

	return (
		<Layout>
			<Box minH="100vh" bg="gray.50" fontFamily="Lato, sans-serif">
				{/* Main Content */}
				<Box maxW="container.xl" mx="auto" px={4} py={8}>
					<Text
						fontSize="4xl"
						fontWeight="bold"
						color="gray.800"
						mb={6}
						fontFamily="Montserrat, sans-serif"
					>
						ফর্ম তালিকা
					</Text>

					<Box bg="white" shadow="md" rounded="lg" p={6} mb={8}>
						{/* Search Resources Section */}
						<SearchOptions
							selectedTagOption={selectedTagOption}
							optionsTag={optionsTag}
							handleTagChange={handleTagChange}
							selectedBoardCategoryOption={selectedBoardCategoryOption}
							optionsBoardCategory={optionsBoardCategory}
							handleBoardCategoryChange={handleBoardCategoryChange}
							selectedSpecificCategoryOptions={selectedSpecificCategoryOptions}
							optionsSpecificCategory={optionsSpecificCategory}
							handleCheckboxSpecificCategoryChange={handleCheckboxSpecificCategoryChange}
							data2={data2}
							selectedBroad={selectedBroad}
							handleBroadSelect={handleBroadSelect}
							selectedSpecific={selectedSpecific}
							handleSpecificSelect={handleSpecificSelect}
							// filteredData={filteredData}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
							handleSearch={handleSearch}
						/>

						<Text
							fontSize="2xl"
							fontWeight="semibold"
							color="gray.800"
							mb={4}
							fontFamily="Montserrat, sans-serif"
						>
							সাম্প্রতিক আপলোডগুলি
						</Text>

						{/* Featured Resources and Recent Uploads */}
						<LibraryResources
							filteredData={filteredData}
							handleDelete={handleDelete}
							handleEditOpen={handleEditOpen}
							handlePreview={handlePreview}
							handleDownload={handleDownload}
						/>
					</Box>

					{/*  pagination  */}
					<Flex justify="center" mt={8}>
						<HStack spacing={2}>
							<IconButton
								aria-label="Previous Page"
								icon={<ChevronLeftIcon />}
								variant="outline"
								onClick={() => handlePageChange(currentPage - 1)}
								isDisabled={currentPage === 1}
							/>
							{currentPage > 1 && (
								<Button
									variant="outline"
									onClick={() => handlePageChange(currentPage - 1)}
								>
									{currentPage - 1}
								</Button>
							)}

							<Button variant="outline" bg={"gray.200"}>
								{currentPage}
							</Button>

							{!(filteredData?.length < itemsPerPage) && (
								<Button
									variant="outline"
									onClick={() => handlePageChange(currentPage + 1)}
								>
									{currentPage + 1}
								</Button>
							)}
							<IconButton
								aria-label="Next Page"
								icon={<ChevronRightIcon />}
								variant="outline"
								onClick={() => handlePageChange(currentPage + 1)}
								isDisabled={filteredData?.length < itemsPerPage}
							/>
						</HStack>
					</Flex>
				</Box>
			</Box>

			{/* Modal for showing file document */}
			<FilePreviewModal
				isOpen={isFileViewOpen}
				onClose={onFileViewClose}
				curFilename={curFilename}
				curFileType={curFileType}
				// previewFile={previewFile}
				from={"fromList"}
			/>

			{/* // edit modal  */}
			<EditModal
				isEditOpen={isEditOpen}
				incidentData={incidentData}
				handleEditClose={handleEditClose}
				reloadFlag={reloadFlag}
				setReloadFlag={setReloadFlag}
			/>
		</Layout>
	);
}
